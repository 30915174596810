import React from "react";
import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import Slider from "../pages/Slider";
import Header from "../pages/Header";
import Footer from "../pages/Footer";
import I1 from "../images/i1.png";
import I2 from "../images/i2.png";
import I3 from "../images/i3.png";
import I4 from "../images/i4.png";
import I5 from "../images/i5.png";
import I6 from "../images/i6.png";
import I7 from "../images/i7.png";
import Apply from "../images/img6.png";
import Blog1 from "../images/img8.png";
import Blog2 from "../images/img9.png";
import Contactf from "../images/img10.png";
import About from "../images/beetanclg.jpg";
import socityone from "../images/recentone.jpg";
import twoday1 from "../images/2day1.jpeg";
import twoday2 from "../images/2day2.jpeg";
import twoday3 from "../images/2day3.jpeg";
import socitytwo from "../images/recenttwo.jpg";
import pdficon from "../images/pdf-icon.jpg";
import pdfAcademic from "../pdf/Annual Datesheet UG-2024.pdf";
import AboutIMG from "../images/principalbeetan.JPG";
import TrainingProgramMay2024 from "../pdf/TrainingProgramMay2024.pdf";
import Minutescomposition2021 from "../pdf/Minutes-composition20-21.pdf";
import Changedatesheet from "../pdf/Change-in-UG-annual-2024.pdf";
import AuctionNotice from "../pdf/AuctionNotice.pdf";
import NoticeregardingElection2024 from "../pdf/NoticeregardingElection2024.pdf";
function Home() {
    const Urlmain = "https://gcbeetan.co.in/admin/uploads/";
    const [user, setUser] = useState([]);
    const fetchData = () => {
      return fetch("https://gcbeetan.co.in/admin/api/api.php/notifications/latest_notice")
            .then((response) => response.json())
            .then((data) => setUser(data));
    }
      useEffect(() => {
      fetchData();
    },[])
    return (
        <>
            <Header />
            <Slider />          
          <div className="mar-main">
                {/* <marquee className="marquee-top" behaviour="scroll" direction="left"><font color="RED">Online admission for the 2024-25 session will start in June 2024. BA first-year students can choose one compulsory subject from English, Hindi, or Sanskrit. Sanskrit is not mandatory for first-year students.</font></marquee> */}
            </div>
            <br />
            <div className="section margin-top_50">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 layout_padding_2">
                            <div className="full">
                                <div className="heading_main text_align_left">
                                    <h2><span>Welcome To</span> Beetan College</h2>
                                </div>
                                <div className="full">
                                    <p>S.D.W.G.Government Degree College Beetan was established on 23rd August, 2004 when the Government of Himachal Pradesh took it over from the private management of Sant Baba Dhangu Wale Ji. Ever since its inception in 1997, it has been imparting quality education to the students of the area. The College has been accredited by the NAAC with grade “B” in December 2016.  It possesses a sprawling campus and is surrounded by lush green fields. The scenic setting provides an ideal atmosphere for pursuing higher studies. It is located at about 28 kms from district headquarters, Una and is well connected by roads from all sides.</p>
                                </div>
                                <div className="full">
                                    {/* <Link to="/about" className="hvr-radial-out button-theme">About more</Link> */}
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 no-padding lib-item" data-category="ui">
                            <h2>Notice Board</h2>
                            <div class="notice">
                              <ul>
                                {user && user.length > 0 && user.map((userObj, index) => (
                                    <li><i class="fa fa-check"></i><a href={Urlmain+userObj.myfile} target="_blank">{userObj.title}</a></li>
                                    ))}
                                </ul>
                              
                                {/* <a className="view-noti" href="#">View All>> </a> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- end section --> */}
            <div class="section margin-top_50 mb-5" style={{ backgroundColor: '#f5f5f5' }}>
                <div class="container">
                    <div class="row pt-3 pb-3">
                        <div class="col-md-6">
                            <div class="full mt-5">
                                <img src={AboutIMG} alt="#" />
                                <div className="about-principal">
                                    <p><strong>Dr. Sunita Goyal</strong></p>
                                    <p><strong>Principal SDWG Govt. College Beetan</strong></p>
                                    <p><strong>Distt. Una H.P.</strong></p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 layout_padding_2">
                            <div class="full">
                                <div class="heading_main text_align_left">
                                    <h2><span>Principal</span> Message</h2>
                                </div>
                                <div class="full">
                                    <p><strong>“You don’t have to be great to start, but you have to start to be great” </strong>
                                        SDWG Govt. College Beetan was established on August 23, 2004 when the government of Himachal Pradesh took it over from the private management of Sant Baba Dhangu Wale ji. It has a sprawling campus spread over 50 Kanals. Since its inception, it has added many feathers to its cap specially when NAAC accredited it with grade “B” in December 2016. Our institution offers graduation courses in Arts, Commerce and Science streams along with PG course in English. The institution is well–equipped with modern Hi-Tech classrooms, wi-fi campus, modern library, science Laboratories, English language lab so that faculty may make use of modern teaching –learning methods and students may become tech-savy.
                                        Various Clubs and units like NCC, NSS, Rangers and Rovers, Career guidance cell, Red Ribbon Club, Sports Club, Eco-Club, Anti-Drug Cell persistently provide a platform for the betterment and holistic development of the students.
                                        We aim that our students graduate and post graduate with qualities and skills that not only enable them to succeed but also equip them to face the challenges of life.
                                        I wish best of fortune and success to the students studying presently in the college and welcome new students for the session 2024-25.
                                        May all be happy.</p>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section padding_bottom-0 mb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="full">
                                <div className="heading_main text_align_center">
                                    <h2><span>RECENT ACTIVITIES</span></h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div id="demo" className="carousel slide" data-ride="carousel">

                                {/* <!-- The slideshow --> */}
                                <div className="carousel-inner">
                                    <div className="carousel-item active">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <div className="full blog_img_popular">
                                                    <img className="img-responsive" src={twoday2} alt="#" />
                                                    <h5 className="text-center">Two days international conference 26-27 july.</h5>
                                                    <p></p>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <div className="full blog_img_popular">
                                                    <img className="img-responsive" src={twoday3} alt="#" />
                                                    <h5 className="text-center">Two days international conference 26-27 july</h5>
                                                    <p></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="carousel-item">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <div className="full blog_img_popular">
                                                    <img className="img-responsive" src={twoday1} alt="#" />
                                                    <h5 className="text-center">Two days international conference 26-27 july.</h5>
                                                    <p></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="carousel-item">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <div className="full blog_img_popular">
                                                    <img className="img-responsive" src={socitytwo} alt="#" />
                                                    <h5 className="text-center">Industrial Visit to Modulus cosmetic pvt. ltd. in the month of February, 2024.</h5>
                                                    <p></p>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <div className="full blog_img_popular">
                                                    <img className="img-responsive" src={socityone} alt="#" />
                                                    <h5 className="text-center">On the occasion of National Science Day, poster making competition is organised on 28 February, 2024.</h5>
                                                    <p></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <!-- Left and right controls --> */}
                                <a className="carousel-control-prev" href="#demo" data-slide="prev">
                                    <span className="carousel-control-prev-icon"></span>
                                </a>
                                <a className="carousel-control-next" href="#demo" data-slide="next">
                                    <span className="carousel-control-next-icon"></span>
                                </a>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {/* <!-- end section --> */}

            {/* <!-- end section --> */}
            <Footer />
        </>
    );
}

export default Home;